<template>
  <div id="round-number-rate-setting" class="container">
    <div class="page-header mb-1">
      <strong>ตั้งค่าอัตราจ่าย</strong>
    </div>

    <div v-if="roundId" class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">
          <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะงวด</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
        </h5>
      </div>
      <div class="card-body">

        <div class="mb-4">
          <h5>
            อัตราจ่าย
            <span class="text-secondary" style="text-decoration: underline;">(เฉพาะงวด)</span>
            วันที่ <span class="text-primary">{{ roundDate }}</span>
            <small class="text-secondary ml-2">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
          </h5>
          <RoundPayrate :data="round" @reload="loadRoundData" />
        </div>

        <div>
          <h5>
            อัตราจ่ายรายตัว
            <span class="text-secondary" style="text-decoration: underline;">(เฉพาะงวด)</span>
            วันที่ <span class="text-primary">{{ roundDate }}</span>
            <small class="text-secondary ml-2">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
          </h5>
          <div class="row">
            <div v-for="bet in marketOpenBets" :key="bet.code" class="col-md-6">
              <NumberRate :bet="bet" :data="round" @reload="loadRoundData" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>
  </div>
</template>
<script>
import { marketOpenBets } from "@/configs/market.config"

import RoundService from "@/services/RoundService"
import moment from '@/helpers/moment'
import cAlert from '@/helpers/alert'
import NumberRate from './components/NumberRate'
import RoundPayrate from './components/RoundPayrate'

export default {
  name: 'NumbersRate',
  components: {
    NumberRate,
    RoundPayrate
  },
  data() {
    return {
      data: null,
      isProcess: false,
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    round() {
      if(!this.data)
        return null

      return this.data.round
    },
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    }
  },
  watch: {
    roundId() {
      this.loadRoundData()
    }
  },
  methods: {
    loadRoundData() {
      if(!this.roundId)
        return

      RoundService.getRoundSetting(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data

          if(response?.data?.market?.marketType === 'lottoset') {
            this.$router.push({name: 'LottosetSetting'})
          }
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.loadRoundData()
  }
}
</script>
