<template>
  <div>
    <table class="table table-bordered table-warning">
      <thead>
        <tr>
          <th class="text-center"></th>
          <th width="13%" v-for="bet in marketOpenBets" :key="bet.code" class="text-center">{{ bet.text }}</th>
          <th width="30" class="text-center">แก้ไข</th>
        </tr>
      </thead>
      <tbody v-if="isAvailable">
        <tr class="alert-warning bg-light">
          <td class="text-center">จ่าย (บาท)</td>
          <td width="13%" v-for="bet in marketOpenBets" :key="bet.code" class="text-center">
            {{ (rates[bet.code].isAvailable ? rates[bet.code].pay : '') | amountFormat }}
          </td>
          <td rowspan="2" width="30" class="text-center">
            <button class="btn btn-sm btn-outline-primary" @click="openModal"><i class="far fa-edit"></i></button>
          </td>
        </tr>
        <tr class="alert-warning bg-light">
          <td class="text-center">คอมฯ (%)</td>
          <td width="13%" v-for="bet in marketOpenBets" :key="bet.code" class="text-center">
            {{ (rates[bet.code].isAvailable ? rates[bet.code].discount : '') | amountFormat }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="alert-warning bg-light">
          <td :colspan="marketOpenBets.length+2" class="text-center">
            <button :disabled="!permSetting" class="btn btn-link p-0" @click="openModal">เปิดใช้อัตราจ่ายเฉพาะงวด</button>
          </td>
        </tr>
      </tbody>
    </table>

    <RoundPayrateModal :data="data" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import { marketOpenBets } from "@/configs/market.config"
import RoundPayrateModal from './RoundPayrateModal'

export default {
  name: 'RoundPayrate',
  props: ['data'],
  components: {
    RoundPayrateModal
  },
  data() {
    return {
      isShowModal: false
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    isAvailable() {
      return this.data?.setRate?.isAvailable
    },
    rates() {
      return this.data?.setRate?.rates
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.Payrate === 'w'
    }
  },
  methods: {
    openModal() {
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.$emit('reload')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
